import {
  Component, Vue,
} from 'vue-property-decorator';
import PageLayout from '@/layout/Page.vue';
import ImageCarousel from '@/components/ImageCarousel.vue';
import YandexMapWrapper from '@/components/YandexMapWrapper.vue';
import LastComments from '@/components/LastComments.vue';
import hostels from '@/assets/hostels.json';
import type {
  MarkerItem,
} from '@/types';

@Component({
  components: {
    PageLayout,
    ImageCarousel,
    YandexMapWrapper,
    LastComments,
  },
  metaInfo() {
    return this.$route.meta?.metaInfo;
  },
})
export default class Home extends Vue {
  public get markers(): MarkerItem[] {
    return hostels
      .filter(({ coords }) => Array.isArray(coords) && coords.length === 2)
      .map((hostel) => ({
        title: hostel.title,
        coords: hostel.coords,
      }));
  }

  public get images(): string[] {
    return hostels
      .map(({ images }) => images)
      .reduce((acc, images) => acc.concat(images), []);
  }
}
