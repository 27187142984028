







import {
  Component,
  Vue,
} from 'vue-property-decorator';

@Component
export default class LastComments extends Vue {
  private mounted(): void {
    window.AnyComment = window.AnyComment || { Comments: [], CommentStream: [] };
    window.AnyComment.CommentStream.push({
      root: 'last-comments',
      app_id: 2510,
      language: 'ru',
      limit: 5,
    });

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://widget.anycomment.io/stream/embed.js';

    const head = document.querySelector('head') as HTMLHeadElement;

    head.appendChild(script);
  }
}
